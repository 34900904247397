import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './4.main.ce7aea3c50b8bde0b8a5.css';
import './Lato.css';
import './Nunito.css';
import './styles__ltr.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


